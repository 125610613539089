
import { renderData } from "@libraryHelpers/dataComponents";

export default {
	name: "CoreBlockRichText",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		blockContent() {
			// Standardized name is 'bodyText', but some sites still have 'richText'
			return this.settings?.bodyText ? this.replaceUrlBasePathInContent(this.settings?.bodyText) :
				this.replaceUrlBasePathInContent(this.settings?.richText);
		},
	},
	methods: {
		replaceUrlBasePathInContent(content) {
			return renderData(content?.replace(
					/href=('|")[\/]/gi,
					`href="${this?.$config?.["_app"]?.basePath}`
				) || "", this.dataSite);
		}
	}
};
